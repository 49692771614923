import type {
  Pagination,
  PaginationQuery,
  SearchTemplateResult,
  Segment,
  SegmentQuery,
  Template,
  TemplateCategory,
} from '@/types'
import { rest } from '@/utils'

export const getTemplates = async (
  params: PaginationQuery<{ tag?: string; category?: string }>,
): Promise<Pagination<Template>> => {
  const res = await rest.get('/v1/templates', { params })

  return res.data
}

export const getUserTemplates = async (params: SegmentQuery<{ userId: string }>): Promise<Segment<Template>> => {
  const res = await rest.get(`/v1/user/${params.userId}templates`, { params })
  return res.data
}

export const postTemplate = async (data: Omit<Template, '_id'>): Promise<void> => {
  const res = await rest.post('/v1/template', data)
  return res.data
}

export const putTemplate = async (data: Template): Promise<void> => {
  const res = await rest.put(`/v1/template/${data.template_id}`, data)
  return res.data
}

export const deleteTemplate = async (id: string): Promise<void> => {
  const res = await rest.delete(`/v1/template/${id}`)
  return res.data
}

export const getTemplate = async (id: string): Promise<Template | null> => {
  const res = await rest.get(`/v1/template/${id}`)
  return res.data
}

export const searchTemplates = async (params: PaginationQuery<{ query: string }>): Promise<SearchTemplateResult> => {
  const res = await rest.get('/v1/templates/search', { params })
  return res.data
}

export const getTemplateCreations = async (
  params: PaginationQuery<{ template_id: string }>,
): Promise<Pagination<Template>> => {
  const { template_id, ...others } = params
  const res = await rest.get(`/v1/template/${template_id}/creations`, { params: others })
  return res.data
}

export const getTemplateCategoryCreations = async (
  params: PaginationQuery<{ template_category: string }>,
): Promise<Pagination<Template>> => {
  const { template_category, ...others } = params
  const res = await rest.get(`/v1/template/category/${template_category}/creations`, { params: others })
  return res.data
}

export const getTemplateCategories = async (): Promise<TemplateCategory[]> => {
  const res = await rest.get('/v1/template/categories')
  return res.data
}

export const likeTemplate = async (template_id: string): Promise<void> => {
  const res = await rest.put(`/v1/template/${template_id}/like`)
  return res.data
}

export const unlikeTemplate = async (template_id: string): Promise<void> => {
  const res = await rest.put(`/v1/template/${template_id}/unlike`)
  return res.data
}
